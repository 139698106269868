import { type ReactNode, useEffect, useState } from "react";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";

import { removeAuthTokens } from "@/utils/authentication/authHelpers";
import { getPageRoute } from "@/utils/constants/pageRoutes";
import {
  getMarketingCookieConsent,
  getStatisticsCookieConsent,
} from "@/utils/cookies/cookieConsentRepository";

import ladyStock from "/public/images/v2/stocks/stocklady.jpg";

import { useAuth } from "../auth/AuthContext";
import { LoadingPage } from "../common/LoadingPage";
import CookieConsentModal from "../cookies/CookieConsentModal";

interface IPreLoginPageProps {
  title?: string;
  children?: ReactNode;
}

const PreLoginPage = ({
  children,
  title = "YoungOnes",
}: IPreLoginPageProps) => {
  const [opened, setOpened] = useState(false);
  const router = useRouter();
  const { user, setToken } = useAuth();
  const region = router?.query?.region || "nl";
  const lang = router?.query?.lang || "nl-NL";

  if (router && router.query && router.query.token) {
    const token = router.query.token ? (router.query.token as string) : null;

    removeAuthTokens();
    setToken(token);
    router.push(getPageRoute("gigsOpen", String(region), String(lang)));
  }

  const isMarketingCookiesAllowed = getMarketingCookieConsent();
  const isStatisticsCookiesAllowed = getStatisticsCookieConsent();

  useEffect(() => {
    if (!isMarketingCookiesAllowed || !isStatisticsCookiesAllowed)
      return setOpened(true);
  }, [isMarketingCookiesAllowed, isStatisticsCookiesAllowed]);

  useEffect(() => {
    if (user)
      router.push(getPageRoute("gigsOpen", String(region), String(lang)));
  }, [lang, region, router, user]);

  if (user) return <LoadingPage />;

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <div className="z-0 !block bg-white lg:relative lg:!flex">
        <div className="z-20 h-2 w-full bg-gradient-blue-pink lg:h-screen lg:w-2 lg:bg-gradient-blue-pink-vertical" />
        <div className="z-10 !block h-[calc(100vh-0.5rem)] w-full rounded-r-[2.2rem] bg-white p-8 md:h-fit md:min-h-[calc(100vh-0.5rem)] lg:absolute lg:left-2 lg:h-screen lg:w-[45%]">
          <div className="max-h-full w-full py-12 md:py-[102px] lg:overflow-y-auto">
            <div className="pb-20 md:pb-0">{children}</div>
          </div>
        </div>
        <div className="relative right-0 ml-auto hidden h-screen w-[60%] overflow-hidden lg:!block">
          <Image
            src={ladyStock}
            priority
            fill
            className="object-cover"
            alt=""
            sizes="(max-width: 2750px) 40vw, 1100px"
          />
        </div>
      </div>
      <CookieConsentModal opened={opened} setOpened={setOpened} />
    </>
  );
};

export default PreLoginPage;
