import React, { useState } from "react";
import Link from "next/link";
import { Trans, useTranslation } from "next-i18next";

import {
  setMarketingCookieConsent,
  setStatisticCookieConsent,
} from "@/utils/cookies/cookieConsentRepository";

import { Button, Modal } from "../ui";
import { AccordionItem, AccordionWrapper } from "../ui/accordion";
import { Toggle } from "../ui/Toggle";

interface ICookieConsent {
  opened: boolean;
  setOpened: (x: boolean) => void;
}

const CookieConsentModal = ({ opened, setOpened }: ICookieConsent) => {
  const [showSelections, setShowSelections] = useState<boolean>(false);
  const [functionalValue, setFunctionalValue] = useState<boolean>(true);
  const [statisticValue, setStatisticValue] = useState<boolean>(false);
  const [marketingValue, setMarketingValue] = useState<boolean>(true);

  const submit = (all: boolean) => {
    setStatisticCookieConsent(statisticValue || all ? "1" : "0");
    setMarketingCookieConsent(marketingValue || all ? "1" : "0");
    setOpened(false);
  };

  const { t } = useTranslation();

  return (
    <Modal
      additionalClassNames={"bg-navyBlue text-white rounded-md"}
      opened={opened}
      onClose={() => null}
      uncloseAble={true}
      withCloseButton={false}
      gradient={false}
    >
      <h1 className="mb-5 text-[1.25rem] font-medium">
        {t("cookies.introTitle")}
      </h1>
      <div className="flex flex-col gap-4">
        <p>
          <Trans i18nKey="cookies.intro.paragraph1">
            We use cookies and process data to ensure the site functions
            properly, analyze usage, improve the user experience, and for online
            marketing purposes. For these purposes, we share data with third
            parties. More information about this can be found in our privacy
            policy.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="cookies.intro.paragraph2">
            By clicking accept, you agree to this. You can always adjust your
            preferences through our{" "}
            <Link
              className="inline text-base text-turquoise-500"
              target="_blank"
              href="https://youngones.com/nl/privacy-policy/"
            >
              privacy policy.
            </Link>
          </Trans>
        </p>

        {showSelections && (
          <div className="selections">
            <AccordionWrapper>
              <AccordionItem
                style="dark"
                endContent={
                  <>
                    <Toggle
                      disabled
                      onClick={() => setFunctionalValue(!functionalValue)}
                      value={true}
                    />
                  </>
                }
                panelContent={
                  <div className="mt-1 text-white">
                    {t("cookies.choices.functional.content")}
                  </div>
                }
                controlContent={
                  <span className="text-white">
                    {t("cookies.choices.functional.title")}
                  </span>
                }
              />
              <AccordionItem
                style="dark"
                endContent={
                  <>
                    <Toggle
                      onClick={() => setStatisticValue(!statisticValue)}
                      value={statisticValue}
                    />
                  </>
                }
                panelContent={
                  <div className="mt-1 text-white">
                    {t("cookies.choices.statistics.content")}
                  </div>
                }
                controlContent={
                  <span className="text-white">
                    {t("cookies.choices.statistics.title")}
                  </span>
                }
              />
              <AccordionItem
                style="dark"
                endContent={
                  <>
                    <Toggle
                      onClick={() => setMarketingValue(!marketingValue)}
                      value={marketingValue}
                    />
                  </>
                }
                panelContent={
                  <div className="mt-1 text-white">
                    {t("cookies.choices.marketing.content")}
                  </div>
                }
                controlContent={
                  <span className="text-white">
                    {t("cookies.choices.marketing.title")}
                  </span>
                }
              />
            </AccordionWrapper>
          </div>
        )}

        <span className="my-5 h-[1px] w-full bg-white" />
        <div className="flex flex-col content-stretch items-stretch justify-between gap-3 md:flex-row md:justify-end">
          {showSelections ? (
            <Button
              type="button"
              className="w-full md:w-auto"
              variant="outline"
              onClick={() => {
                submit(false);
              }}
            >
              {t("cookies.acceptSelection")}
            </Button>
          ) : (
            <Button
              type="button"
              className="w-full md:w-auto"
              variant="outline"
              onClick={() => {
                setShowSelections(!showSelections);
              }}
            >
              {t("cookies.manageSelection")}
            </Button>
          )}
          <Button
            className="w-full md:w-auto"
            type="button"
            onClick={() => {
              submit(true);
            }}
          >
            {t("cookies.acceptAll")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CookieConsentModal;
